import React from 'react'

const Article = ({ children, title, color }) => {
    return (
      <article className="min-h-screen pb-8 pt-24">
        {title && (
          <div className="my-8 w-1/4">
            <h1 className={"flex lg:items-center py-8 lg:before:h-0.5 lg:before:grow lg:before:bg-"+ color + " lg:before:mr-8 xlg:flex-col xlg:after:block xlg:after:border-b-2 xlg:after:w-[25vw] xlg:after:border-" + color + " xlg:after:pr-8" }>
              <p className={"text-" + color + " tracking-widest lg:text-5xl text-2xl xlg:text-right xlg:px-auto"}>{title}</p>
            </h1>
          </div>
        )}
        {children}
      </article>
    )
  }
  

export default Article