import * as  React from 'react'
import Modal from "react-modal";
import { StaticImage } from 'gatsby-plugin-image'
import Layout from '../components/layout'
import Container from '../components/Container'
import Article from '../components/Article'
import MetaTag from '../components/MetaTag'
import "../styles/modalStyle.css"

Modal.setAppElement("#___gatsby");

// Step 2: Define your component
const MemberPage = () => {
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const handleOpenModal = (num) => {
    setIsOpen(num) 
  }
  const handleCloseModal = () => {
    setIsOpen(false)
  }
  

  return (
    <div className='bg-[#B1ADAD]'>
      <Layout>
        <Article title="member" color="white">
          <Container>
            <div className='flex flex-wrap justify-center'>
              <div className='inline-block lg:w-1/4 lg:m-8 md:w-2/5 md:m-4 mb-12 mb-12'>
                <button className="button" onClick={() => handleOpenModal(0)}>
                  <div className="p-4 bg-base-100 shadow-xl">
                    <figure><StaticImage src='../images/Profile/01_Fukushima.jpg' alt='譜久島 基' className=''/></figure>
                  </div>
                </button>
                <div className="text-center my-8">
                  <p className='text-xl text-white'>CEO / 譜久島 基</p>
                </div>
              </div>
              <div className='inline-block lg:w-1/4 lg:m-8 md:w-2/5 md:m-4 mb-12 mb-12'>
                <button className="button" onClick={() => handleOpenModal(1)}>
                  <div className="p-4 bg-base-100 shadow-xl">
                    <figure><StaticImage src='../images/Profile/02_Sha.jpg' alt='車 行健' className=''/></figure>
                  </div>
                </button>
                <div className="text-center my-8">
                      <p className='text-xl text-white'>COO / 車 行健</p>
                  </div>
              </div>
              <div className='inline-block lg:w-1/4 lg:m-8 md:w-2/5 md:m-4 mb-12 mb-12'>
                <button className="button" onClick={() => handleOpenModal(2)}>
                  <div className="p-4 bg-base-100 shadow-xl">
                    <figure><StaticImage src='../images/Profile/03_Nagano.jpg' alt='長野 達行' className=''/></figure>
                  </div>
                </button>
                <div className="text-center my-8">
                      <p className='text-xl text-white'>CCO / 長野 達行</p>
                  </div>
              </div>
              <div className='inline-block lg:w-1/4 lg:m-8 md:w-2/5 md:m-4 mb-12 mb-12'>
                <button className="button" onClick={() => handleOpenModal(3)}>
                  <div className="p-4 bg-base-100 shadow-xl">
                    <figure><StaticImage src='../images/Profile/04_Nakajima.jpg' alt='中島 将太' className=''/></figure>
                  </div>
                </button>
                <div className="text-center my-8">
                      <p className='text-xl text-white'>CBO / 中島 将太</p>
                  </div>
              </div>
              <div className='inline-block lg:w-1/4 lg:m-8 md:w-2/5 md:m-4 mb-12 mb-12'>
                <button className="button" onClick={() => handleOpenModal(4)}>
                  <div className="p-4 bg-base-100 shadow-xl">
                    <figure><StaticImage src='../images/Profile/05_Suzuki.jpg' alt='鈴木 智' className=''/></figure>
                  </div>
                </button>
                <div className="text-center my-8">
                      <p className='text-xl text-white'>鈴木 智</p>
                  </div>
              </div>
              <div className='inline-block lg:w-1/4 lg:m-8 md:w-2/5 md:m-4 mb-12 mb-12'>
                <button className="button" onClick={() => handleOpenModal(5)}>
                  <div className="p-4 bg-base-100 shadow-xl">
                    <figure><StaticImage src='../images/Profile/06_Nohara.jpg' alt='野原 拓也' className=''/></figure>
                  </div>
                </button>
                <div className="text-center my-8">
                      <p className='text-xl text-white'>野原 拓也</p>
                  </div>
              </div>
              <div className='inline-block lg:w-1/4 lg:m-8 md:w-2/5 md:m-4 mb-12 mb-12'>
                <button className="button" onClick={() => handleOpenModal(8)}>
                  <div className="p-4 bg-base-100 shadow-xl">
                    <figure><StaticImage src='../images/Profile/09_Aoki.jpg' alt='青木 穂高' className=''/></figure>
                  </div>
                </button>
                <div className="text-center my-8">
                      <p className='text-xl text-white'>青木 穂高</p>
                  </div>
              </div>
            </div>
          </Container>
        </Article>
      </Layout>
      <Modal
        isOpen={(modalIsOpen === 0)}
        onRequestClose={() => handleCloseModal()}
        shouldCloseOnEsc={true}
        shouldCloseOnOverlayClick={true}
        className="Modal"
        overlayClassName="Overlay"
      >
        <div className='md:flex w-full h-full xmd:overflow-auto'>
            <div className="flex item-center md:w-2/5 p-2">
              <figure><StaticImage src='../images/Profile/01_Fukushima.jpg' alt='譜久島 基' className=''/></figure>
            </div>
            <div className='p-6 md:w-3/5 md:overflow-auto'>
              <p className='md:my-8 fullhd:text-2xl md:text-xl xs:text-3xl text-xl'>CEO / 譜久島 基</p>
              <p className='fullhd:text-xl fullhd:leading-relaxed 
                md:pt-4 md:text-md md:leading-relaxed
                pt-8 xs:text-lg xs:leading-relaxed
                text-sm leading-relaxed'>
                広告代理店にて製造業・不動産業をメインとしたプロモーション・マーケティング支援の提案営業に従事。
                自社製品/サービスのマーケティングに注力すべく業種を変えて上場企業を渡り歩き、
                事業責任者として製品企画からマーケティング、販路拡大に至るまで担当。​<br /><br />
                直近のベンチャー企業ではEコマース事業と教育事業の立ち上げ等にも事業統括責任者として参画。​
                映像と記事を掛け合わせた独自のプロモーション構想を掲げ、ONESEL株式会社の起業に至る。</p>
            </div>
        </div>
      </Modal>
      <Modal
        isOpen={(modalIsOpen === 1)}
        onRequestClose={() => handleCloseModal()}
        shouldCloseOnEsc={true}
        shouldCloseOnOverlayClick={true}
        className="Modal"
        overlayClassName="Overlay"
      >
        <div className='md:flex w-full h-full xmd:overflow-auto'>
            <div className="flex item-center md:w-2/5 p-2">
              <figure><StaticImage src='../images/Profile/02_Sha.jpg' alt='車 行健' className=''/></figure>
            </div>
            <div className='p-6 md:w-3/5 md:overflow-auto'>
              <p className='md:my-8 fullhd:text-2xl md:text-xl xs:text-3xl text-xl'>COO / 車 行健</p>
              <p className='fullhd:text-xl fullhd:leading-relaxed 
                md:pt-4 md:text-md md:leading-relaxed
                pt-8 xs:text-lg xs:leading-relaxed
                text-sm leading-relaxed'>
                広告制作会社にてグラフィックやWeb系サービスの制作に携わり、アカウントプロデューサーとして従事。
                その後、フリーランスとして独立し、映像監督のマネージャー・ミュージックビデオの制作業務をメインに、
                写真展のプロデューサー、地下アイドルの立ち上げ支援などエンターテイメント領域で幅広い業務を経験。<br /><br />
                直近ではベンチャー企業にて映像制作スクールの立ち上げに携わり、
                大手クライアントとリレーションを築き疑似的なクライアントワークを学べる場を提供するなど、
                独自性の高いサービス設計を行う。</p>
            </div>
        </div>
      </Modal>
      <Modal
        isOpen={(modalIsOpen === 2)}
        onRequestClose={() => handleCloseModal()}
        shouldCloseOnEsc={true}
        shouldCloseOnOverlayClick={true}
        className="Modal"
        overlayClassName="Overlay"
      >
        <div className='md:flex w-full h-full xmd:overflow-auto'>
            <div className="flex item-center md:w-2/5 p-2">
              <figure><StaticImage src='../images/Profile/03_Nagano.jpg' alt='長野 達行' className=''/></figure>
            </div>
            <div className='p-6 md:w-3/5 md:overflow-auto'>
              <p className='md:my-8 fullhd:text-2xl md:text-xl xs:text-3xl text-xl'>CCO / 長野 達行</p>
              <p className='fullhd:text-xl fullhd:leading-relaxed 
                md:pt-4 md:text-md md:leading-relaxed
                pt-8 xs:text-lg xs:leading-relaxed
                text-sm leading-relaxed'>
                番組制作会社で約15年間、専任の映像編集エディターとして従事。
                ATP上方番組大賞やギャラクシー賞に選抜される作品づくりに数多く参加。
                近年は映像制作の教育事業に参画し、映像制作者向けカリキュラムの構成・制作を担当。
                専門誌『ビデオサロン』での連載執筆、クリエイター向けウェビナー講師など、自身の知識を広く発信。<br /><br />
                After Effects・illustrator・Photoshop・Premiere Pro・Blenderなど幅広く網羅している。</p>
            </div>
        </div>
      </Modal>
      <Modal
        isOpen={(modalIsOpen === 3)}
        onRequestClose={() => handleCloseModal()}
        shouldCloseOnEsc={true}
        shouldCloseOnOverlayClick={true}
        className="Modal"
        overlayClassName="Overlay"
      >
        <div className='md:flex w-full h-full xmd:overflow-auto'>
            <div className="flex item-center md:w-2/5 p-2">
              <figure><StaticImage src='../images/Profile/04_Nakajima.jpg' alt='中島 将太' className=''/></figure>
            </div>
            <div className='p-6 md:w-3/5 md:overflow-auto'>
              <p className='md:my-8 fullhd:text-2xl md:text-xl xs:text-3xl text-xl'>CBO / 中島 将太</p>
              <p className='fullhd:text-xl fullhd:leading-relaxed 
                md:pt-4 md:text-md md:leading-relaxed
                pt-8 xs:text-lg xs:leading-relaxed
                text-sm leading-relaxed'>
                株式会社共同テレビジョンにてTVCMやインフォマーシャルなどの制作に携わり、
                プロダクションマネージャーとして従事。その後、映像制作プロダクションAOI TYO Holdings
                にてスズキ株式会社やサッポロビール・サッポロ一番などの大型TVCM案件を担当。<br /><br />
                近年はベンチャー企業にて企画をメインに、映像コンテストやウェビナー・LP制作・映像制作など
                のプロデュース全般を担当している。</p>
            </div>
        </div>
      </Modal>
      <Modal
        isOpen={(modalIsOpen === 4)}
        onRequestClose={() => handleCloseModal()}
        shouldCloseOnEsc={true}
        shouldCloseOnOverlayClick={true}
        className="Modal"
        overlayClassName="Overlay"
      >
        <div className='md:flex w-full h-full xmd:overflow-auto'>
            <div className="flex item-center md:w-2/5 p-2">
              <figure><StaticImage src='../images/Profile/05_Suzuki.jpg' alt='鈴木 智' className=''/></figure>
            </div>
            <div className='p-6 md:w-3/5 md:overflow-auto'>
              <p className='md:my-8 fullhd:text-2xl md:text-xl xs:text-3xl text-xl'>鈴木 智</p>
              <p className='fullhd:text-xl fullhd:leading-relaxed 
                md:pt-4 md:text-md md:leading-relaxed
                pt-8 xs:text-lg xs:leading-relaxed
                text-sm leading-relaxed'>
                大手PCメーカーにてSB C&S株式会社・株式会社ビックカメラをはじめとした大手商社・量販店の
                アカウント営業を経て購買部に所属し貿易事務並びに経理業務に従事。<br /><br />
                直近のベンチャー企業では、Eコマース事業の立ち上げに際して契約書雛形の作成から
                締結を含め信憑書類の管理を担いながら、大手クライアントのアカウント営業を担当している。</p>
            </div>
        </div>
      </Modal>
      <Modal
        isOpen={(modalIsOpen === 5)}
        onRequestClose={() => handleCloseModal()}
        shouldCloseOnEsc={true}
        shouldCloseOnOverlayClick={true}
        className="Modal"
        overlayClassName="Overlay"
      >
        <div className='md:flex w-full h-full xmd:overflow-auto'>
            <div className="flex item-center md:w-2/5 p-2">
              <figure><StaticImage src='../images/Profile/06_Nohara.jpg' alt='野原 拓也' className=''/></figure>
            </div>
            <div className='p-6 md:w-3/5 md:overflow-auto'>
              <p className='md:my-8 fullhd:text-2xl md:text-xl xs:text-3xl text-xl'>野原 拓也</p>
              <p className='fullhd:text-xl fullhd:leading-relaxed 
                md:pt-4 md:text-md md:leading-relaxed
                pt-8 xs:text-lg xs:leading-relaxed
                text-sm leading-relaxed'>
                不動産広告会社の社内SEとして内製アプリケーションの開発を担当したのち、
                ハードウェア開発に興味を持ちPCメーカーにて製品のブランド兼プロダクト担当として活動。
                ブランド担当として各種登壇やイベント、協業案件に関わる傍ら製品の立ち上げや
                訴求プランの考案、広報資料の作成などを実施。<br /><br />
                近年では、これまでの経験を活かし自社サービスサイトの管理やフロントエンドの設計、
                社内インフラの整備などマルチに活動。ハードウェアからソフトに至るまでの広い知見と情報
                の言語化を得意とする。</p>
            </div>
        </div>
      </Modal>
      <Modal
        isOpen={(modalIsOpen === 8)}
        onRequestClose={() => handleCloseModal()}
        shouldCloseOnEsc={true}
        shouldCloseOnOverlayClick={true}
        className="Modal"
        overlayClassName="Overlay"
      >
        <div className='md:flex w-full h-full xmd:overflow-auto'>
            <div className="flex item-center md:w-2/5 p-2">
              <figure><StaticImage src='../images/Profile/09_Aoki.jpg' alt='青木 穂高' className=''/></figure>
            </div>
            <div className='p-6 md:w-3/5 md:overflow-auto'>
              <p className='md:my-8 fullhd:text-2xl md:text-xl xs:text-3xl text-xl'>青木 穂高</p>
              <p className='fullhd:text-xl fullhd:leading-relaxed 
                md:pt-4 md:text-md md:leading-relaxed
                pt-8 xs:text-lg xs:leading-relaxed
                text-sm leading-relaxed'>
                武蔵野美術大学造形学部デザイン情報学科を卒業後、映像制作プロダクション株式会社TYOに新卒で入社。
                TVCM・WEBCM・MVなど広告案件の企画・制作に携わる。<br /><br />
                その後、ベンチャー企業にてイベントのプランニングや 映像制作の企画を担当。
                大手カメラメーカーのプロモーションを中心に、年間を通したプロジェクトを複数立案し、長期的なプロモーションをサポート。</p>
            </div>
        </div>
      </Modal>
    </div>
  )
}

export default MemberPage

export function Head({ location }) {
  return (
    <MetaTag
      pathname={location.pathname}
      title='member | ONESEL'
    >
    </MetaTag>
  )
}